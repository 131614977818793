<template>
  <v-data-table
    :page="page"
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :items-per-page="itemsPerPage"
    :item-class="getItemClass"
    :server-items-length="totalCount"
    :options.sync="options"
    :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
  >
    <template v-slot:[`item.deactivatedDate`]="{ item }">
      {{ convertDate(item.deactivatedDate) }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon v-if="canUpdate" class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-menu offset-y v-if="canDeleteAndArchive">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>

        <v-list dense class="pt-1 pb-1">
          <v-list-item
            @click="deleteItem(item)"
            class="gray--text text--darken-3"
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="body-2 font-weight-regular">
              Sil
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="changeStatus(item)"
            class="gray--text text--darken-3"
          >
            <v-list-item-icon>
              <v-icon> mdi-archive-arrow-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="text-left body-2 font-weight-regular">
              {{ item.isActive ? "Deaktivləşdir" : "Aktivləşdir" }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    isFetchingItems: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Məntəqə / ünvan", value: "sideOrAddress", sortable: false },
        { text: "Lokasiya nöqtəsi", value: "location", sortable: false },
        { text: "Son avadanlıq", value: "lastEquipment", sortable: false },
        {
          text: "Son avadanlığın İP ünvanı",
          value: "lastEquipmentIP",
          sortable: false,
        },
        {
          text: "MNG Vlan",
          value: "mngVLAN",
          sortable: false,
        },
        { text: "MNG İP", value: "mngIP", sortable: false },
        { text: "Servis Vlanı", value: "serviceVLAN", sortable: false },
        { text: "Uplink", value: "uplink", sortable: false },

        { text: "Qeyd", value: "note", sortable: false },
        {
          text: "Deaktiv edilmə tarixi",
          value: "deactivatedDate",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "13%",
          align: "end",
        },
      ],
      options: {},
    };
  },
  watch: {
    options: {
      handler(value) {
        const { page, itemsPerPage } = value;
        if (page > 0 || itemsPerPage >= 10) {
          this.$emit("optionsChanged", {
            page: page,
            itemsPerPage: itemsPerPage,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    editItem(item) {
      this.$emit("editItem", item);
    },
    deleteItem(item) {
      this.$emit("deleteItem", item);
    },
    changeStatus(item) {
      this.$emit("changeStatus", item);
    },
    getItemClass(item) {
      if (!item.isActive) {
        return "red accent-1";
      }
      return "";
    },
    convertDate(date) {
      if (date != null) return this.$moment(date).format("DD.MM.YYYY");
      else return "-";
    },
  },
  computed: {
    canDeleteAndArchive() {
      return this.$store.getters.inRole("Admin");
    },
    canUpdate() {
      return this.$store.getters.inRole("ServicesPoint:edit");
    },
  },
};
</script>
